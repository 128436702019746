import _handlebars from "../dist/cjs/handlebars";
import _printer from "../dist/cjs/handlebars/compiler/printer";
import _fs from "fs";
var exports = {};
// USAGE:
// var handlebars = require('handlebars');

/* eslint-disable no-var */
// var local = handlebars.create();
var handlebars = _handlebars["default"];
var printer = _printer;
handlebars.PrintVisitor = printer.PrintVisitor;
handlebars.print = printer.print;
exports = handlebars; // Publish a Node.js require() handler for .handlebars and .hbs files

function extension(module, filename) {
  var fs = _fs;
  var templateString = fs.readFileSync(filename, "utf8");
  module.exports = handlebars.compile(templateString);
}
/* istanbul ignore else */


if ({}) {
  ({})[".handlebars"] = extension;
  ({})[".hbs"] = extension;
}

export default exports;